import React, { useState, useEffect} from "react"
import { useStaticQuery, graphql } from "gatsby"


const Menu = () => {
    // Querying and storing Data Stored in Gatsby GraphQL query.
    const initial_data_query = useStaticQuery(graphql`
    query {
      wpgraphql {
        pages(where: {title: "About"}) {
            nodes {
                acf_about_Menu_Tabs {
                    expertiseTabName
                    focusTabName
                    reachTabName
                  }
            }
          }
        }
    }
    `)
    let initial_state_data = initial_data_query.wpgraphql;

    // Storing this data as the State so that we can update the state later with Live Data
    const [ existingData, updateData ] = useState(initial_state_data);
    const [loader, setLoader] = useState(true);

    // Fetching the Live data from the created WORDPRESS API 
    
    useEffect(()=>{
        let curr_api = process.env.GATSBY_API_URL;
        
        fetch(`${curr_api}/graphql`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: `
                {   
                    pages(where: {title: "About"}) {
                        nodes {
                            acf_about_Menu_Tabs {
                                expertiseTabName
                                focusTabName
                                reachTabName
                              }
                        }
                      }
                }
                `
            }),
        })
        .then(res => res.json())
        .then(data => updateData(data.data))
        .then(setLoader(false))
        .catch(error => console.log(`Some Error Occurred! The Error :${error}`));
        setTimeout(function(){
        const links = document.querySelectorAll(".about-floating-menu-warpper a");
        for (const link of links) {
          link.addEventListener("click", clickHandler);
        }
      },1000)

      setTimeout(function(){

      let url = window.location.href;
    let section = '#'+url.split('#')[1];
    
    if(section!=='#undefined'){
      scrollIt(
        document.querySelector(section),
        300,
        'easeOutQuad',
        //() => console.log(`Just finished scrolling to ${window.pageYOffset}px`)
    );
    }
  },500)

    }, [])

    
    // Storing All Live Data in Variables to display it
    let acf_about_Menu_Tabs = existingData.pages.nodes[0].acf_about_Menu_Tabs;   

    function scrollIt(destination, duration = 200, easing = 'linear', callback) {

        const easings = {
          linear(t) {
            return t;
          },
          easeInQuad(t) {
            return t * t;
          },
          easeOutQuad(t) {
            return t * (2 - t);
          },
          easeInOutQuad(t) {
            return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
          },
          easeInCubic(t) {
            return t * t * t;
          },
          easeOutCubic(t) {
            return (--t) * t * t + 1;
          },
          easeInOutCubic(t) {
            return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
          },
          easeInQuart(t) {
            return t * t * t * t;
          },
          easeOutQuart(t) {
            return 1 - (--t) * t * t * t;
          },
          easeInOutQuart(t) {
            return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t;
          },
          easeInQuint(t) {
            return t * t * t * t * t;
          },
          easeOutQuint(t) {
            return 1 + (--t) * t * t * t * t;
          },
          easeInOutQuint(t) {
            return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t;
          }
        };
      
        const start = window.pageYOffset;
        const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

        let menu_height = 62;
        if(window.innerWidth<768){
            menu_height = 49;
        }
      
        const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
        const destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop - menu_height;
        const destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset);
      
        if ('requestAnimationFrame' in window === false) {
          window.scroll(0, destinationOffsetToScroll);
          if (callback) {
            callback();
          }
          return;
        }
      
        function scroll() {
          const now = 'now' in window.performance ? performance.now() : new Date().getTime();
          const time = Math.min(1, ((now - startTime) / duration));
          const timeFunction = easings[easing](time);
          window.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));
      
          if (window.pageYOffset === destinationOffsetToScroll) {
            if (callback) {
              callback();
            }
            return;
          }
      
          requestAnimationFrame(scroll);
        }
      
        scroll();
      }
    
    // Cache selectors
    
   
    function clickHandler(e) {
        e.preventDefault();

        // const links = document.querySelectorAll(".about-floating-menu-warpper a");
    //     let _this = this;
        
    //     // setTimeout(function(){          
    //       for (const link of links) {
    //     //    link.classList.remove('active');
    //       }
          
    //     // },500)
    //     // setTimeout(function(){          
    //   //    _this.classList.add('active');
          
    // //    },1000)
        
        const href = this.getAttribute("href");    
        scrollIt(
            document.querySelector(href),
            300,
            'easeOutQuad',
            //() => console.log(`Just finished scrolling to ${window.pageYOffset}px`)
        );

        
    }

    
    
   

    return(
        <>
        {
            loader ? '' 
            : (
                    <div className="about-floating-menu-warpper">
                        <a href="#expertise-section" className="about-floating-menu-link">{acf_about_Menu_Tabs.expertiseTabName}</a>
                        <a href="#focus-section" className="about-floating-menu-link">{acf_about_Menu_Tabs.focusTabName}</a>
                        <a href="#reach-section" className="about-floating-menu-link">{acf_about_Menu_Tabs.reachTabName}</a>
                    </div>
                
            )
        }
        </>
    );

 
}

export default Menu;