import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Banner from '../components/About/Banner'
import Tabbing from "../components/About/Tabbing"
import SEO from "../components/seo"

const About = () => {
  const initial_data_query = useStaticQuery(graphql`
    query {
      wpgraphql {
        page(id: "cG9zdDoyMQ==") {
          title
        }
      }
    }
  `)

  
  let page_title = initial_data_query.wpgraphql;
  let title = page_title.page.title;

  return (
    <div className="about-main-wrapper">
      
    <Layout>
        <SEO title={title}/>
      
        <div id="aboutpage">
            <Banner/>
            <Tabbing />
            
        </div>
    </Layout>
  </div>
  )
  }

export default About
