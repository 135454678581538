import React from "react"
import Menu from "./Tabbings/Menu"
import Expertise from "./Tabbings/Expertise"
import Focus from "./Tabbings/Focus"
import Reach from "./Tabbings/Reach"

class Tabbing extends React.Component {

    componentDidMount(){
        window.addEventListener('scroll', this.changetabstate);       
    }

    changetabstate = () => {
        setTimeout(function(){
        const tabs = document.querySelectorAll('.about-floating-menu-link');
        const sections = document.querySelectorAll('.about-inner-tabbing-section');
        if(sections.length>0){
            let index = sections.length;  
            
            let menu_height = 100;
            if(window.innerWidth<768){
                menu_height = 82;
            }
            
            while(--index && window.scrollY + menu_height < sections[index].offsetTop) {
            }        
            tabs.forEach((link) => link.classList.remove('active'));
            tabs[index].classList.add('active');
        }
    },100)

      }
    
    render(){
        return(
                    <div className="about-tabbing-section">
                        <Menu />
                        <div className="about-tabbing-inner-sections-wrapper">
                            <Expertise />
                            <Focus />
                            <Reach />
                            
                        </div>
                        
                    </div>
        );
    }
}

export default Tabbing;